import '../node_modules/animate.css/animate.css';
import '../node_modules/bootstrap-select/dist/css/bootstrap-select.css';
import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import './assets.scss';

import '../sdist/inject_tailwind.css';

import $ from 'jquery';
const jQuery = $;
window.jQuery = jQuery;
import CookiesEuBanner from 'cookies-eu-banner';
import URI from 'urijs';
import balanceText from 'balance-text';
import 'flexibility';
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/affix.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/alert.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/dropdown.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/tooltip.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/modal.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/button.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/popover.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/carousel.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/scrollspy.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse.js');
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap/tab.js');
import 'bootstrap3-typeahead';
import 'bootstrap-select';
import reel from '../node_modules/jquery-reel/jquery.reel.js';
jQuery.fn.reel = reel;

import './scripts/dropdown-doubletap.js';
import './scripts/bootstrap-dropdownhover.js';
import './scripts/hero-slide.js';
import './scripts/news-slide.js';
import './scripts/person-slide.js';
import './scripts/project-slide.js';
import './scripts/photoswipe.js';
import './scripts/map.js';
import Swiper from 'swiper/dist/js/swiper.js';
import Colcade from 'colcade';
import LazyLoad from 'vanilla-lazyload/dist/lazyload';
import _ from 'lodash';
import 'aplayer/dist/APlayer.min.css';
import APlayer from 'aplayer';

$.get('/search/autocomplete/', function(data){
  $('.nav-search-typeahead').typeahead({ source:data.result });
},'json');

balanceText('.balance-text');
balanceText('.balance-text a');

$('.slider-detail').slick({
  infinite: true,
  slidesToShow: 2,
  arrows: true,
  dots: false,
  cssEase: 'linear',
  appendArrows: $('.slider-detail').parent(),
  prevArrow: '<div class="arrow-left"><span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>' +
             '<span class="sr-only">Vorherige</span></div>',
  nextArrow: '<div class="arrow-right"><span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>' +
             '<span class="sr-only">Nächste</span>'
});
var $status = $('.slide-info');
var $SliderHeroDetail = $('.slider-hero-detail');

$SliderHeroDetail.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    $status.text(i + '/' + slick.slideCount);
});

$SliderHeroDetail.slick({
  infinite: true,
  slidesToShow: 1,
  centerMode: false,
  variableWidth: false,
  adaptiveHeight: true,
  arrows: true,
  dots: false,
  fade: false,
  cssEase: 'linear',
  prevArrow: '<div class="arrow-left"><span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>' +
             '<span class="sr-only">Vorherige</span></div>',
  nextArrow: '<div class="arrow-right"><span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>' +
             '<span class="sr-only">Nächste</span>'
});


try {
  var photogalleryOptions = {shareButtons: localizedShareButtons};
} catch (ReferenceError) {
  var photogalleryOptions = {};
}

$.extend( photogalleryOptions, {
  itemFilter: function() {
    return $(this).parents('.slick-cloned').length === 0;
  },
  captionSelector: '.caption, .Zcaption'
});

$('.herounit-gallery').photogallery(photogalleryOptions);

$(document).ready(function() {
  $('[data-toggle="reel"]').on('click', function() {
    jQuery.fn.reel();
    var target = $(this).data('target');
    $(target).addClass('show');
  });
  $('[data-dismiss="reel"]').on( 'click', function() {
    $(this).parents('.modal-reel').removeClass('show');
  });

  $( '.navbar-stiftungen .navbar-nav li:has(ul)' ).doubleTapToGo();

  var banner = new CookiesEuBanner(function(){
      (function(b,o,i,l,e,r){b.GoogleAnalyticsObject=l;b[l]||(b[l]=
      function(){(b[l].q=b[l].q||[]).push(arguments)});b[l].l=+new Date;
      e=o.createElement(i);r=o.getElementsByTagName(i)[0];
      e.src='https://www.google-analytics.com/analytics.js';
      r.parentNode.insertBefore(e,r)}(window,document,'script','ga'));
      ga('create','UA-110477772-1', 'auto');ga('send','pageview');ga('set', 'anonymizeIp', true);
  });

  $('.Zteaser').each(function () {
    if ($(this).find('.swiper-slide').length > 1) {
      var TeaserSwiper = new Swiper(this, {
        slidesPerView: 1,
        speed: 500,
        loop: true,
        autoHeight: true,
        navigation: {
          prevEl: '.teaser__arrow--prev',
          nextEl: '.teaser__arrow--next',
          disabledClass: 'teaser__arrow--disabled',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
      });
      $(this).hover(function () {
        this.swiper.autoplay.stop();
      }, function () {
        this.swiper.autoplay.start();
      });
    }
  });

  var campusRegex = /campus/g;
  if (campusRegex.test(window.location.hostname)) {
    $('.content-body a[href*="//"]').each(function() {
      var target = new URI($(this).attr('href'));
      target.normalize();
      if (target.is('absolute')) {
         // This is an external link
         $(this).attr({
          'target': '_blank',
          'rel': 'noopener'
         });
      }
    });
  }

  if ($('.grid').length) {
    var colc = new Colcade('.grid', {
      columns: '.grid__col',
      items: '.grid__item'
    });

    var gridRelayout = _.debounce(function () {
        //grid.masonry('layout');
        console.log('layout');
      },
      100, {
      leading: true,
      trailing: true
    });

    var myLazyLoad = new LazyLoad({
      elements_selector: ".img-fitting",
      callback_load: gridRelayout,
      callback_ender: gridRelayout,
    });
  }

  $('audio').each(function() {
    let ele = $(this);
    const ap = new APlayer({
      container: ele.siblings('.player')[0],
      audio: [{
        name: ele.data('audio-name'),
        artist: ' ',
        url: ele.find('source').attr('src'),
      }]
    });
    ele.hide();
  })
});
